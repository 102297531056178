import {SliceCaseReducers, ValidateSliceCaseReducers} from '@reduxjs/toolkit';

const syncLoading = (loadingHandler: any, loadingKey: string, action = 'end') => {
    const updatedLoadHandler = {...loadingHandler};
    action === 'start' ? (updatedLoadHandler[loadingKey] = true) : delete updatedLoadHandler[loadingKey];
    return updatedLoadHandler;
};

const START = 'START';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

const makeHttpActionTypes = (reducer: string, action: string) => ({
    [action]: `${reducer}/${action}`,
    [`${action}_${START}`]: `${reducer}/${action}/${START}`,
    [`${action}_${SUCCESS}`]: `${reducer}/${action}/${SUCCESS}`,
    [`${action}_${FAIL}`]: `${reducer}/${action}/${FAIL}`,
});
const makeActionType = (reducer: string, action: string) => `${reducer}/${action}`;

const createSliceReducers =
    <State>() =>
    <CaseReducers extends SliceCaseReducers<State>>(reducers: ValidateSliceCaseReducers<State, CaseReducers>) =>
        reducers;

export {createSliceReducers, makeActionType, makeHttpActionTypes, syncLoading};
